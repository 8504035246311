.navmobile {
  width: 100vw;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  background: var(--warna-hijau);
  margin-top: 5rem;
  animation: down 0.3s ease;
}

@keyframes down {
  from {
    transform: translateY(-5rem);
    opacity: 0;
  }
  from {
    opacity: translateY(0);
  }
}
