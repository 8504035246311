* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  /* overflow-x: hidden; */
}

html {
  --warna-putih: #ffffff;
  --warna-kuning: #fff200;
  --warna-hijau: #004b1c;
  --warna-hijau-sec: #50b848;
  font-family: "Lato", "Montserrat", "Franklin Gothic Medium", "Arial Narrow",
    Arial, sans-serif;
}

body {
  background-color: var(--warna-background);
}

a {
  text-decoration: none;
  color: inherit;
}

/* SEMENTARTA STYLENYA UNTUK UKURAN 1920 MENYESUAIKAN PADA DESAIN FIGMA*/
/* UKURAN LAYAR MAC INI 1440 */
/* @media (max-width: 359px) {
  html {
    font-size: 9px;
  }
}

@media (min-width: 360px) {
  html {
    font-size: 10.5px;
  }
} */

@media only screen and (max-width: 767px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) {
  html {
    font-size: 14.45px;
  }
}

@media only screen and (min-width: 1366px) {
  html {
    font-size: 15px;
  }
}

@media only screen and (min-width: 1440px) {
  html {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1920px) {
  html {
    font-size: 19px;
  }
}
@media only screen and (min-width: 2560px) {
  html {
    font-size: 27px;
  }
}
