.buy {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buy-wrapper {
  width: 52.632rem;
  /* height: 18.526rem; */
  border-radius: 0.526rem;
  background-color: rgb(0, 0, 0);
  color: var(--warna-kuning);
  position: relative;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  animation: fadein 0.5s ease;
}

.wrapper-img {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 2rem;
  height: 2rem;
  border-radius: 0.526rem;
  transition: all 0.2s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.wrapper-img:hover {
  background-color: rgb(226, 2, 2);
  cursor: pointer;
}

.buy-wrapper img {
  width: 1.2rem;
  height: 1.2rem;
}

.buy-title {
  text-align: center;
}
.buy-title h4 {
  text-transform: uppercase;
  font-size: 1.579rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.buy-title p {
  text-transform: uppercase;
  font-size: 1.053rem;
}

.logo-toko-kontainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.logo-toko-wrapper {
  padding: 0.5rem;
  width: 12.5rem;
  text-align: center;
  transition: all 0.3s ease-in;
  border-radius: 0.526rem;
  overflow: hidden;
}
.logo-toko-wrapper img {
  width: 7.895rem;
  height: 7.092rem;
  object-fit: scale-down;
  transition: all 0.5s ease;
}
.logo-toko-wrapper img:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.logo-toko-wrapper span {
  text-transform: uppercase;
  font-size: 0.895rem;
  display: block;
  margin-top: -0.5rem;
}

.tokopedia {
  color: #42b549;
}
.shopee {
  color: #ee4d2d;
}
.blibli {
  color: #268acb;
}
.bukalapak {
  color: #ed0946;
}

.img-tokopedia {
  color: #42b549;
}
.img-shopee {
  color: #ee4d2d;
}
.img-blibli {
  color: #268acb;
}
.img-bukalapak {
  color: #ed0946;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }

  to {
    opacity: 1;
  }
}

/* SETUP MOBILE */
@media only screen and (max-width: 768px) {
  .buy-wrapper {
    width: 90vw;
  }

  .buy-title h4 {
    font-size: 0.857rem;
  }

  .buy-title p {
    font-size: 0.571rem;
  }

  .logo-toko-wrapper {
    width: 45%;
  }

  .logo-toko-wrapper img {
    height: 5rem;
  }

  .logo-toko-wrapper span {
    font-size: 0.695rem;
  }
}
