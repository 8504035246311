.hubungi-kami {
  background-image: url("../../images/background-other-page.webp");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  /* height: 110vh; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding-bottom: 5rem;
}

.hubungi-kami-container {
  margin-top: 10rem;
  margin-bottom: 22rem;
  width: 80vw;
  /* height: 60vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

/* alamat */
.hubungi-kami-alamat-wrapper {
  width: 27.895rem;
  height: 32.8rem;
  border-radius: 1rem;
  padding: 2rem;
  color: rgb(255, 255, 255);
  background-color: var(--warna-hijau);
  /* position: relative; */
  margin-right: -0.5rem;
  z-index: 2;
  display: flex;
  align-items: center;
}

.hubungi-kami-alamat-kontent {
  height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.detail {
  display: flex;
  /* gap: 1.5rem; */
}

.image-detail {
  line-height: 2.5rem;
  margin-right: 1.5rem;
}
.image-detail img {
  width: 1.2rem;
  height: 1.2rem;
}

.alamat-detail h4 {
  font-size: 1.053rem;
  line-height: 1.7rem;
  font-weight: 700;
}
.alamat-detail p {
  font-size: 0.895rem;
  line-height: 1.5rem;
}

/* form */

.hubungi-kami-kontents-wrapper {
  width: 27.895rem;
  /* height: 27.895rem; */
  border-radius: 1rem;
  border: 0.1rem solid rgba(255, 242, 0, 1);
  padding: 1.5rem;
  color: rgb(255, 255, 255);
  /* position: relative; */
}
.hubungi-kami-title {
  margin-bottom: 1rem;
}
.hubungi-kami-title h4 {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}

.form-hubungi-kami {
  position: relative;
  width: 100%;
  height: 83%;
}

.group-hubungi-kami label {
  font-size: 0.947rem;
  color: var(--warna-kuning);
  display: block;
  margin-bottom: 0.5rem;
}

.group-hubungi-kami input {
  width: 100%;
  height: 2.368rem;
  background: none;
  border-radius: 1rem;
  outline: none;
  border: solid 0.1rem var(--warna-kuning);
  padding: 0.5rem;
  margin-bottom: 2rem;
  font-size: 0.842rem;
  color: rgb(255, 255, 255);
}

.group-hubungi-kami input::placeholder {
  font-size: 0.842rem;
  color: rgb(255, 255, 255);
}

.button-wrapper-hubungi-kami button {
  width: 100%;
  height: 2.368rem;
  border: none;
  border-radius: 0.789rem;
  background-color: var(--warna-kuning);
  color: var(--warna-hijau);
  font-size: 0.947rem;
  font-weight: 700;
  transition: all 0.5s ease;
  text-transform: uppercase;
  /* position: absolute; */
  bottom: 1rem;
}

.button-wrapper-hubungi-kami button:hover {
  background-color: var(--warna-hijau);
  color: rgb(255, 255, 255);
  cursor: pointer;
}

/* SETUP MOBILE */
@media only screen and (max-width: 768px) {
  .hubungi-kami {
    background-image: url("../../images/background-other-page-mobile.png");
  }

  .hubungi-kami-container {
    width: 90vw;
    flex-direction: column-reverse;
  }

  .hubungi-kami-title h4 {
    font-size: 1.429rem;
  }

  .hubungi-kami-kontents-wrapper {
    width: 100%;
  }

  .hubungi-kami-alamat-wrapper {
    width: 100%;
    margin-top: -1rem;
    margin-right: 0rem;
  }
}
