.produk-kami {
  height: 130vh;
  width: 100vw;
  margin-top: -55vh;
  position: relative;
  overflow: hidden;
}

.produk-kami img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.produk-kami .air-terjun3 {
  position: absolute;
  width: 17vw;
  height: 37vh;
  left: 48vw;
  top: 17vh;
}

.air-terjun3 img {
  width: 100%;
  height: 100%;
  opacity: 0.2;
}

.produk-kami .air-terjun2 {
  position: absolute;
  width: 28vw;
  height: 59vh;
  left: 39vw;
  top: 57vh;
}

.air-terjun2 img {
  width: 100%;
  height: 100%;
  opacity: 0.2;
}

.produk-kami-container {
  width: 80vw;
  margin: 0 auto;
  margin-top: 20rem;
}
.produk-kami-contents {
  position: relative;
  width: 100%;
  height: 100%;
}

.produk-kami-contents h3 {
  font-family: "Gold Girls";
  font-size: 3.947rem;
  font-weight: 400;
  line-height: 5.105rem;
  letter-spacing: 0.01rem;
  color: var(--warna-kuning);
  padding: 0 0.5rem;
  text-shadow: -0.211rem -0.211rem 0 var(--warna-hijau),
    0.211rem -0.211rem 0 var(--warna-hijau),
    -0.211rem 0.211rem 0 var(--warna-hijau),
    0.211rem 0.211rem 0 var(--warna-hijau);
}

.produk-kami-container-produk {
  width: 96vw;
  margin: 0 auto;
  /* margin-top: 22rem; */
}
.produk-kami-contents-produk {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 20;
  display: flex;
}

.produk-group {
  width: 25.263rem;
  height: 27.895rem;
  position: relative;
}

.produk-group:not(:last-child) {
  border-right: 0.1rem solid white;
}

.produk-group-png {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.produk-group-png .png {
  /* width: 9.053rem;
  height: 10.105rem; */
  width: 13.053rem;
  height: 17.105rem;
  object-fit: contain;
}

.produk-group-png .png-kaleng {
  width: 11.789rem;
  height: 17.632rem;
}

.produk-group-png .png-bottle {
  width: 6.368rem;
  height: 23.053rem;
}
.produk-group-png .png-sejuk {
  width: 6.632rem;
  height: 16.211rem;
}

.produk-group-svg {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
}

.produk-group-svg .svg {
  width: 2.197rem;
  height: 2.636rem;
  bottom: 2.636rem;
  left: 1.3rem;
}

.produk-group-svg p {
  color: white;
  top: 93%;
  left: 1rem;
  position: relative;
  font-size: 1.579rem;
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  .produk-kami-container {
    width: 90vw;
  }

  .produk-kami-contents-produk {
    overflow: auto;
    padding-bottom: 2rem;
  }

  .produk-kami-contents h3 {
    font-size: 2.143rem;
  }

  .produk-kami .air-terjun3 {
    width: 38vw;
  }

  .produk-kami .air-terjun2 {
    width: 70vw;
    left: 20vw;
  }

  .produk-kami-container-produk {
    width: auto;
    overflow: hidden;
  }

  .produk-group {
    height: 23rem;
    flex-shrink: 0;
    width: 80vw;
  }

  .produk-group-png .png {
    height: 22.105rem;
  }

  .produk-group-svg .svg {
    width: 3.197rem;
    height: 2.636rem;
    bottom: 2rem;
    left: 0.5rem;
  }

  .produk-group-svg {
    height: 95%;
  }

  .produk-group-svg p {
    top: 93%;
    left: 1rem;
    bottom: 1rem;
    font-size: 1.286rem;
  }
}
