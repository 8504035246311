.detail-produk {
  background-image: url("../../images/background-other-page.webp");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  /* height: 160vh; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding-bottom: 5rem;
}

.detail-produk-container {
  margin-top: 6rem;
  margin-bottom: 20rem;
  width: 80vw;
  position: relative;
  z-index: 1;
}
.detail-produk-title {
  display: flex;
  justify-content: flex-end;
  color: var(--warna-kuning);
}

.detail-produk-title h3 {
  font-family: "Gold Girls";
  font-size: 3.947rem;
  font-weight: 400;
  line-height: 5.105rem;
  letter-spacing: 0.01rem;
  padding: 0 0.5rem;
  text-shadow: -0.211rem -0.211rem 0 var(--warna-hijau),
    0.211rem -0.211rem 0 var(--warna-hijau),
    -0.211rem 0.211rem 0 var(--warna-hijau),
    0.211rem 0.211rem 0 var(--warna-hijau);
}

.detail-produk-konten {
  width: 100%;
  height: 22rem;
  overflow: hidden;
  display: flex;
  margin-bottom: 2rem;
}

.detail-produk-konten .detail-slider {
  /* border: 0.1rem solid white; */
  flex: 1;
  position: relative;
  overflow: hidden;
}

.detail-produk-konten .detail-deskripsi {
  flex: 1.3;
  color: rgb(255, 255, 255);
  overflow: hidden;
}

.detail-deskripsi p {
  font-size: 1.053rem;
  line-height: 1.5rem;
  letter-spacing: 0.055rem;
}
.detail-deskripsi p::first-letter {
  text-transform: capitalize;
}

.detail-tab {
  width: 100%;
  height: 3.947rem;
  border: 0.1rem solid var(--warna-kuning);
  border-radius: 1rem;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.detail-tab button {
  border: none;
  background: none;
  border-radius: 1rem;
  width: 11.579rem;
  height: 2.895rem;
  font-size: 0.895rem;
  font-weight: 700;
  color: rgb(255, 255, 255);
  transition: all 0.5s ease;
}

.detail-tab button:hover {
  background: var(--warna-hijau);
  color: var(--warna-kuning);
  cursor: pointer;
}

.detail-tab .active {
  background: var(--warna-hijau);
  color: var(--warna-kuning);
  cursor: pointer;
}

.detail-tab button:nth-child(3) {
  background: var(--warna-kuning);
  color: var(--warna-hijau);
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail-tab button:hover:nth-child(3) {
  background: rgb(255, 252, 95);
}

.detail-tab button img {
  margin-right: 0.46rem;
}

.detail-tab-deskripsi {
  /* font-size: 0.947rem; */
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
}

table,
tr,
td {
  border: none;
}

.detail-tab-deskripsi {
  margin: 1rem 0;
}
.detail-tab-deskripsi table tr td {
  padding: 0.1rem 0.1rem;
  font-size: 0.947rem;
}

.detail-tab-deskripsi p {
  font-size: 0.947rem;
  line-height: 1.4rem;
  padding: 0.1rem 0.2rem;
}

/* Produk Terkait */
.produk-terkait {
  display: flex;
  justify-content: flex-start;
  margin: 1rem 0;
}
.detail-produk-kontents {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1rem;
  overflow: hidden;
}

.detail-produk-kontents-wrapper {
  width: 22.632rem;
  height: 19.474rem;
  border-radius: 0.526rem;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.5s ease;
  margin: 1.2rem 0;
}
.detail-produk-kontents-wrapper:hover {
  /* transform: scale(1.02); */
  box-shadow: 0px 0.5rem 0.05rem rgb(251, 255, 0),
    0 10px 20px rgb(250, 250, 250);
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.7);
}
.detail-produk-kontents-wrapper:hover .button-detail-produk {
  visibility: visible;
  opacity: 1;
}

.detail-produk-kontents-wrapper:hover .nama-detail-produk {
  color: rgba(255, 255, 255, 0.3);
}

.foto-detail-produk {
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0.5rem;
}

.foto-detail-produk .sachet {
  /* width: 6.368rem; */
  /* height: 7.105rem; */
  width: 30%;
  object-fit: cover;
  height: 100%;
}

.foto-detail-produk .chingku {
  width: 5.105rem;
  /* height: 7.105rem; */
}

.foto-detail-produk .pet {
  width: 4.368rem;
  /* height: 7.105rem; */
}

.foto-detail-produk .sejuk {
  width: 4.629rem;
  /* height: 7.105rem; */
}

.nama-detail-produk {
  color: rgb(255, 255, 255);
  height: 20%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nama-detail-produk h5 {
  font-size: 1.053rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}

.button-detail-produk {
  position: absolute;
  width: 100%;
  height: 20%;
  bottom: 1.9rem;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.75s linear;
}

.image-wrapper {
  width: 6rem;
  display: flex;
  justify-content: space-between;
}

.image-wrapper img {
  width: 2.368rem;
  height: 2.368rem;
}

/* SETUP MOBILE */

@media only screen and (max-width: 768px) {
  .detail-produk {
    background-image: url("../../images/background-other-page-mobile.png");
  }

  .detail-produk-container {
    width: 90vw;
  }

  .detail-produk-title {
    justify-content: flex-start;
    margin: 0;
  }

  .detail-produk-title h3 {
    font-size: 2.143rem;
    margin-bottom: 0.7rem;
    line-height: 2.6rem;
  }

  .detail-produk-konten {
    display: block;
    height: auto;
  }

  .detail-produk-konten .detail-slider {
    margin-bottom: 1rem;
  }

  .detail-deskripsi p {
    font-size: 1rem;
    letter-spacing: 0.055rem;
  }

  .detail-produk-kontents-wrapper {
    width: 43.5vw;
    height: 10.141rem;
    margin-bottom: 1rem;
  }

  .detail-produk-kontents-wrapper:hover {
    /* transform: scale(1.02); */
    box-shadow: 0px 0.2rem 0.02rem rgb(251, 255, 0),
      0 5px 10px rgb(250, 250, 250);
  }

  .nama-detail-produk h5 {
    font-size: 0.7rem;
  }

  .button-detail-produk {
    bottom: 1.3rem;
  }

  .button-detail-produk .image-wrapper {
    width: 5rem;
  }

  .button-detail-produk .image-wrapper img {
    width: 1.7rem;
    height: 1.7rem;
  }
  .detail-tab {
    height: 2.947rem;
    border-radius: 0.5rem;
  }

  .detail-tab button {
    border-radius: 0.357rem;
    width: 7.211rem;
    height: 1.803rem;
    font-size: 0.857rem;
  }

  .detail-tab button img {
    margin-right: 0.2rem;
    width: 0.8rem;
  }
}
