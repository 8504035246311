.promo-menarik {
  width: 100vw;
  height: 100vh;
  margin-top: -30rem;
  position: relative;
  z-index: 9;
}

.promo-menarik img {
  position: absolute;
  width: 100%;
  height: 75%;
}

.daun-jatuh {
  position: relative;
  margin-top: 10rem;
}

.promo-menarik-container {
  width: 80vw;
  margin: 0 auto;
  margin-top: 10rem;
}
.promo-menarik-contents {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.promo-menarik-contents h3 {
  font-family: "Gold Girls";
  font-size: 3.947rem;
  font-weight: 400;
  line-height: 5.105rem;
  letter-spacing: 0.01rem;
  color: var(--warna-kuning);
  padding: 0 0.5rem;
  text-shadow: -0.211rem -0.211rem 0 var(--warna-hijau),
    0.211rem -0.211rem 0 var(--warna-hijau),
    -0.211rem 0.211rem 0 var(--warna-hijau),
    0.211rem 0.211rem 0 var(--warna-hijau);
  margin-top: 12rem;
}

.promo-menarik-contents .icon-promo {
  width: 2.632rem;
  height: 2.632rem;
  position: relative;
  margin: 0 auto;
  animation: blinker 1.5s linear infinite;
  margin-top: -2rem;
  cursor: pointer;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.icon-promo img {
  width: 100%;
  height: 100%;
  position: absolute;
}

.promo-menarik-contents .box-produk {
  width: 23.684rem;
  height: 19.474rem;
  background: rgba(0, 0, 0, 0.55);
  border-radius: 0.526rem;
  margin: 0 auto;
  margin-top: 1rem;
  color: white;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 1s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    margin-bottom: -18rem;
    transform: rotateY(90deg);
  }

  to {
    opacity: 1;
  }
}

.box-produk img {
  width: 6rem;
  height: 12.368rem;
  position: relative;
  object-fit: cover;
}

.box-produk h4 {
  font-weight: 700;
  font-size: 1.053rem;
  height: 1.6rem;
  text-transform: uppercase;
  text-align: center;
}

.box-produk p {
  font-size: 0.947rem;
  height: 1.6rem;
  /* line-height: 1.158rem; */
  color: var(--warna-kuning);
}

.box-produk button {
  width: 9.263rem;
  height: 2rem;
  border-radius: 1rem;
  color: var(--warna-kuning);
  background: var(--warna-hijau);
  border: none;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-transform: uppercase;
  transition: all 0.3s linear;
  padding: 0.5rem 0;
}

.box-produk button:hover {
  cursor: pointer;
  background-color: var(--warna-kuning);
  color: var(--warna-hijau);
}

button .plus {
  width: 1.6rem;
  height: 1.6rem;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: black;
  position: absolute;
  right: 0;
}

.wrapper-loading {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

/* Mobile */

@media only screen and (max-width: 768px) {
  .promo-menarik-container {
    width: 90vw;
  }

  img.daun-jatuh {
    height: 50%;
  }

  .promo-menarik-contents .icon-promo {
    margin-top: 0px;
    width: 1.632rem;
    height: 1.632rem;
  }

  .promo-menarik-contents .box-produk {
    width: 80%;
    /* height: 18rem; */
    padding: 0.8rem;
  }

  .promo-menarik-contents h3 {
    font-size: 2.143rem;
    margin-top: 12rem;
  }

  .box-produk img {
    height: 11.368rem;
  }
}
