.daun-wrapper {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.daun-wrapper img {
  width: 100%;
  height: 100%;
}
