.intro {
  position: relative;
  height: 120vh;
  width: 100vw;
}

.intro img {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
}

.intro .logo-utama {
  position: absolute;
  width: 10vw;
  height: 14vh;
  left: 46vw;
  top: 35vh;
  z-index: 6;
}

.logo-utama img {
  width: 100%;
  height: 100%;
  /* transform: rotate(3.92deg); */
}

.intro .air-terjun {
  position: absolute;
  width: 10vw;
  height: 45.5vh;
  left: 46vw;
  top: 43vh;
}

.air-terjun img {
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.intro .lebah {
  position: absolute;
  width: 40vw;
  height: 35vh;
  left: 30vw;
  top: 36vh;
}

.lebah img {
  width: 100%;
  height: 100%;
}

/* Mobile */

@media only screen and (max-width: 768px) {
  .intro .logo-utama {
    width: 30vw;
    left: 38vw;
    /* height: 14vh; */
  }

  .intro .air-terjun {
    width: 30vw;
    left: 36vw;
    top: 42vh;
  }

  .intro .lebah {
    width: 65vw;
    left: 20vw;
  }
}
