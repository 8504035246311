.login {
  background-image: url("../../images/background-other-page.webp");
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: center; */
  position: relative;
  width: 100%;
  /* height: 110vh; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding-bottom: 5rem;
}

.login-container {
  margin-top: 10rem;
  margin-bottom: 20rem;
  width: 80vw;
  /* height: 60vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.login-kontents-wrapper {
  width: 27.895rem;
  height: 27.895rem;
  border-radius: 1rem;
  border: 0.1rem solid rgba(255, 242, 0, 1);
  padding: 1rem;
  background-color: rgba(0, 0, 0, .5);
  /* position: relative; */
}
.login-title {
  margin-bottom: 1rem;
}
.login-title h4 {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}

.form-login {
  position: relative;
  width: 100%;
  height: 83%;
}

.group-login label {
  font-size: 0.947rem;
  color: var(--warna-kuning);
  display: block;
  margin-bottom: 1rem;
}

.group-login input {
  width: 100%;
  height: 2.368rem;
  background: none;
  border-radius: 1rem;
  outline: none;
  border: solid 0.1rem var(--warna-kuning);
  padding: 0.5rem;
  margin-bottom: 2rem;
  font-size: 0.842rem;
  color: rgb(255, 255, 255);
}

.group-login input::placeholder {
  font-size: 0.842rem;
  color: rgb(255, 255, 255);
}

.button-wrapper-login button {
  width: 100%;
  height: 2.368rem;
  border: none;
  border-radius: 0.789rem;
  background-color: var(--warna-kuning);
  color: var(--warna-hijau);
  font-size: 0.947rem;
  font-weight: 700;
  transition: all 0.5s ease;
  text-transform: uppercase;
  position: absolute;
  bottom: 2rem;
}

.button-wrapper-login button:hover {
  background-color: var(--warna-hijau);
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.keterangan-tambahan-login {
  display: block;
  text-align: center;
}

.keterangan-tambahan-login p {
  font-size: 0.632rem;
  text-align: center;
  color: #fff;
}

/* SETUP MOBILE */
@media only screen and (max-width: 768px) {
  .login {
    background-image: url("../../images/background-other-page-mobile.png");
    height: 100vh;
  }

  .login-container {
    width: 90vw;
  }

  .login-kontents-wrapper {
    height: 24.876rem;
  }

  .login-title h4 {
    font-size: 1.5rem;
  }
  .keterangan-tambahan-login {
    position: absolute;
    bottom: 3rem;
    left: 9.5rem;
  }
}
