.artikel {
  background-image: url("../../images/background-other-page.webp");
  /* background-position: center; */
  /* background-attachment: fixed; */
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  /* height: 110vh; */
  overflow: hidden;
  /* padding-bottom: 5rem; */
}

.artikel-container {
  margin-top: -5.5rem;
  margin-bottom: 27rem;
}

/* SETUP MOBILE */
@media only screen and (max-width: 768px) {
  .artikel {
    background-image: url("../../images/background-other-page-mobile.png");
  }
}
