.pop-up-produk {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pop-up-produk-wrapper {
  width: 52.632rem;
  border-radius: 0.526rem;
  background-color: rgb(0, 0, 0);
  color: var(--warna-kuning);
  position: relative;
  padding: 1rem;
}

.pop-wrapper-img {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 2rem;
  height: 2rem;
  border-radius: 0.526rem;
  transition: all 0.2s ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.pop-wrapper-img:hover {
  background-color: rgb(226, 2, 2);
  cursor: pointer;
}

.pop-up-kontainer {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.pop-up-wrapper {
  padding: 0.5rem;
  width: 30%;
  text-align: center;
  transition: all 0.3s ease-in;
  border-radius: 0.526rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.pop-up-wrapper img {
  position: relative;
  width: 100%;
  transition: all 0.5s ease;
}

.pop-up-wrapper img:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.pop-up-wrapper span {
  text-transform: uppercase;
  font-size: 1.2rem;
  display: block;
  color: var(--warna-putih);
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(-3rem);
  }

  to {
    opacity: 1;
  }
}

/* SETUP MOBILE */
@media only screen and (max-width: 768px) {
  .pop-up-produk-wrapper {
    width: 90vw;
  }

  .pop-up-wrapper {
    width: 45%;
  }

  .pop-up-wrapper img {
    /* height: 5rem; */
    height: 100%;
  }

  .pop-up-wrapper span {
    font-size: 0.695rem;
  }
}
