.promo {
  background-image: url("../../images/background-other-page.webp");
  /* background-position: center; */
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  /* height: 110vh; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding-bottom: 5rem;
}

.promo-container {
  margin-top: 6rem;
  margin-bottom: 22rem;
  width: 80vw;
  position: relative;
  z-index: 1;
}

.promo-container-kosong {
  margin-top: 6rem;
  margin-bottom: 22rem;
  width: 80vw;
  position: relative;
  z-index: 1;
  height: 41vh;
}

.promo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6rem;
  margin-bottom: 2rem;
}

.promo-title {
  flex: 1.8;
}

.promo-title h3 {
  font-family: "Gold Girls";
  font-size: 3.947rem;
  font-weight: 400;
  letter-spacing: 0.01rem;
  color: var(--warna-kuning);
  padding: 0 0.5rem;
  text-shadow: -0.211rem -0.211rem 0 var(--warna-hijau),
    0.211rem -0.211rem 0 var(--warna-hijau),
    -0.211rem 0.211rem 0 var(--warna-hijau),
    0.211rem 0.211rem 0 var(--warna-hijau);
}

.promo-kontents {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* gap: 2rem; */
}

.promo-kontents-wrapper {
  width: 22.632rem;
  height: 23.481rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  /* overflow: hidden; */
  margin-bottom: 3rem;
}

.foto-promo {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 10.184rem;
}

.foto-promo img {
  position: absolute;
  width: 100%;
  height: 100%;
}

.detail-promo {
  width: 100%;
  height: 13.297rem;
  padding: 1.3rem;
  margin-top: 10.184rem;
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0 0 1rem 1rem;
  border-right: 0.1rem solid rgba(255, 242, 0, 0.5);
  border-left: 0.1rem solid rgba(255, 242, 0, 0.5);
  border-bottom: 0.1rem solid rgba(255, 242, 0, 0.5);
}

.nama-promo {
  width: 100%;
}

.nama-promo h5 {
  font-size: 1.211rem;
  font-weight: 600;
}

.periode-promo {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.img-periode {
  width: 1.198rem;
  height: 1.416rem;
  position: relative;
}

.img-periode img {
  width: 100%;
  height: 100%;
  position: absolute;
}

.detail-periode span {
  font-size: 0.632rem;
}

.detail-periode p {
  font-size: 0.789rem;
}

.button-wrapper {
  display: flex;
  justify-content: center;
}

.button-wrapper a {
  width: 14.711rem;
  height: 2.546rem;
  border: none;
  border-radius: 0.789rem;
  background-color: var(--warna-kuning);
  color: var(--warna-hijau);
  font-size: 0.947rem;
  font-weight: 700;
  transition: all 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-wrapper a:hover {
  background-color: var(--warna-hijau);
  color: rgb(255, 255, 255);
  cursor: pointer;
}

/* SETUP MOBILE */
@media only screen and (max-width: 768px) {
  .promo {
    background-image: url("../../images/background-other-page-mobile.png");
  }

  .promo-container {
    width: 90vw;
  }
  .promo-container-kosong {
    height: 42vh;
  }

  .promo-title h3 {
    font-size: 2.143rem;
    text-shadow: -0.111rem -0.111rem 0 var(--warna-hijau),
      0.111rem -0.111rem 0 var(--warna-hijau),
      -0.111rem 0.111rem 0 var(--warna-hijau),
      0.111rem 0.111rem 0 var(--warna-hijau);
  }

  .promo-kontents {
    flex-wrap: nowrap;
    width: auto;
    overflow: auto;
    /* gap: 1rem; */
    padding-right: 1rem;
  }

  .promo-kontents-wrapper {
    height: 19.929rem;
    width: 19.214rem;
    margin-bottom: 2rem;
    margin-right: 1.5rem;
    position: relative;
  }

  .detail-promo {
    width: 17rem;
    height: 13.297rem;
    padding: 1.5rem 0.5rem;
    margin-top: 7.184rem;
  }

  .foto-promo {
    position: absolute;
    left: 1.1rem;
    height: 8.184rem;
    width: 17rem;
  }

  .nama-promo h5 {
    font-size: 1.019rem;
  }

  .detail-periode p {
    font-size: 0.664rem;
  }

  .button-wrapper button {
    width: 12.489rem;
    height: 2.161rem;
    border-radius: 0.357rem;
    font-size: 0.947rem;
  }
}
