.profil {
  width: 100%;
  background-image: url("../../images/background-other-page.webp");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 7rem;
}

.profil-container {
  width: 80vw;
  margin-top: 8rem;
  margin-bottom: 17rem;
  position: relative;
  z-index: 2;
}

.detail-tab-profil {
  margin: 5rem auto;
  width: 50%;
  height: 3.947rem;
  border: 0.1rem solid var(--warna-kuning);
  border-radius: 1rem;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.detail-tab-profil button {
  border: none;
  background: none;
  border-radius: 1rem;
  width: 11.579rem;
  height: 2.895rem;
  font-size: 0.895rem;
  font-weight: 700;
  color: rgb(255, 255, 255);
  transition: all 0.5s ease;
}

.detail-tab-profil button:hover {
  background: var(--warna-hijau);
  color: var(--warna-kuning);
  cursor: pointer;
}

.detail-tab-profil .active {
  background: var(--warna-hijau);
  color: var(--warna-kuning);
  cursor: pointer;
}

.wrapper-profil,
.wrapper-poin {
  width: 65vw;
  margin: 0 auto;
  /* background-color: white; */
  padding: 1rem;
}

.wrapper-profil .logo-profil,
.logo-poin {
  width: 13rem;
  /* height: 10rem; */
  overflow: hidden;
  /* background-color: violet; */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.figure-image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.figure-image img {
  width: 60%;
  /* height: 80%; */
  object-fit: cover;
}

.logo-profil button {
  background: none;
  border: 0.1rem solid var(--warna-kuning);
  height: 2.368rem;
  border-radius: 0.789rem;
  font-size: 0.895rem;
  color: var(--warna-kuning);
  transition: all 0.5s ease;
  font-weight: 600;
  letter-spacing: 0.1rem;
}

.logo-profil button:hover {
  background: var(--warna-hijau);
  color: rgb(255, 255, 255);
  border: none;
  cursor: pointer;
  box-shadow: 0 0.1rem 0.2rem rgba(129, 255, 205, 0.4);
  color: #fff;
  transform: translateY(-0.15rem);
}

.isi-profil {
  margin-top: 3rem;
  color: var(--warna-kuning);
}

.group__input {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.isi-profil .group-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  font-size: 1.053rem;
}

.group-input label {
  flex: 1;
}

.group-input input {
  outline: none;
  flex: 4;
  background: none;
  border: none;
  border-bottom: 0.01rem solid #dcf4ff;
  color: rgb(255, 255, 255);
  font-size: 1.316rem;
  padding: 0.2rem 0;
}

.group-input input::placeholder {
  font-size: 1.316rem;
  color: rgb(255, 255, 255);
}

/* Poin */

.logo-poin {
  color: rgb(255, 255, 255);
  text-transform: uppercase;
}

.jumlah-poin {
  margin: 0.5rem auto;
}

.jumlah-poin .poin-angka {
  font-size: 1.316rem;
  font-weight: 700;
}
.jumlah-poin .poin-kata {
  font-size: 0.895rem;
  font-weight: 700;
  margin-left: 0.2rem;
}

.jenis-member {
  margin: 0 auto;
}

.jenis-member img {
  width: 1.2rem;
  height: 1.2rem;
  object-fit: cover;
}
.jenis-member .tipe-member {
  font-size: 1.316rem;
  font-weight: 700;
  margin-left: 0.2rem;
}

.input-poin {
  display: flex;
  justify-content: center;
  margin: 3rem 0;
}

.input-poin input {
  width: 38.421rem;
  height: 3.947rem;
  outline: none;
  border: 0.2rem solid rgba(255, 255, 0, 0.35);
  background: none;
  border-radius: 0.789rem;
  font-size: 1.316rem;
  font-style: italic;
  color: rgb(255, 255, 255);
  text-align: center;
  text-transform: uppercase;
}

.input-poin input::placeholder {
  font-style: italic;
  color: rgb(255, 255, 255);
}

.kontent-poin {
  color: rgb(255, 255, 255);
}

.header-table,
.isi-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  height: 3.5rem;
}

.tanggal,
.isi-tanggal {
  flex: 1.5;
}
.kode-voucher,
.isi-kode-voucher {
  flex: 1.5;
}
.status,
.isi-status {
  flex: 2;
  text-align: center;
}
.poin,
.isi-poin {
  flex: 1;
  text-align: right;
}

.isi-poin {
  color: var(--warna-kuning);
}

.isi-table .isi-tanggal {
  text-transform: capitalize;
}

.isi-table {
  border-bottom: 0.05rem solid rgb(255, 255, 255);
  height: 6rem;
}

.merah {
  background-color: rgb(255, 0, 0);
  padding: 1rem 0;
  border-radius: 0.526rem;
}

.kuning {
  border: 0.2rem solid var(--warna-kuning);
  color: var(--warna-kuning);
  padding: 1rem 0;
  border-radius: 0.526rem;
}

.kuning-bg {
  background-color: rgb(255, 255, 0);
  color: var(--warna-hijau);
  padding: 1rem 0;
  border-radius: 0.526rem;
}

.wrapper-voucher {
  width: 80vw;
  min-height: 24vh;
  color: rgb(255, 255, 255);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: center;
}

.voucher-kontents-wrapper {
  width: 22.632rem;
  height: 23.481rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 1rem;
  flex-wrap: wrap;
  /* overflow: hidden; */
}

.foto-voucher {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 10.184rem;
}
.foto-voucher img {
  position: absolute;
  width: 100%;
  height: 100%;
}
.detail-voucher {
  width: 100%;
  /* height: 13.297rem; */
  padding: 1.3rem;
  margin-top: 1.184rem;
  color: rgb(255, 255, 255);
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  border-radius: 0 0 1rem 1rem;
  border-right: 0.1rem solid rgba(255, 242, 0, 0.5);
  border-left: 0.1rem solid rgba(255, 242, 0, 0.5);
  border-bottom: 0.1rem solid rgba(255, 242, 0, 0.5);
}

.nama-voucher {
  width: 100%;
}

.nama-voucher h5 {
  font-size: 1.053rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
}

.voucher-button-wrapper {
  display: flex;
  justify-content: center;
}

.voucher-button-wrapper button {
  width: 7.355rem;
  height: 1.98rem;
  border: none;
  border-radius: 0.789rem;
  background-color: var(--warna-kuning);
  color: var(--warna-hijau);
  font-size: 0.947rem;
  font-weight: 700;
  transition: all 0.5s ease;
}

.voucher-button-wrapper button:hover {
  cursor: pointer;
  background-color: var(--warna-hijau);
  color: var(--warna-kuning);
}

/* SETUP MOBILE */
@media only screen and (max-width: 768px) {
  .profil {
    background-image: url("../../images/background-other-page-mobile.png");
  }

  .profil-container {
    width: 90vw;
  }

  .detail-tab-profil {
    width: 100%;
  }

  .wrapper-profil,
  .wrapper-poin {
    width: 100%;
    margin-bottom: 3rem;
    padding: 0;
  }

  .wrapper-voucher {
    width: auto;
    flex-wrap: nowrap;
    overflow: auto;
    height: 35vh;
  }

  .voucher-kontents-wrapper {
    width: 70vw;
    height: 17.481rem;
    flex-shrink: 0;
    margin-right: 2rem;
  }

  .detail-voucher {
    margin-top: 7.184rem;
  }

  .isi-profil .group-input {
    display: block;
    margin-bottom: 1rem;
  }

  .group__input {
    display: block;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .group-input label {
    display: block;
    width: 100%;
  }

  .group-input input {
    width: 100%;
  }

  .status {
    text-align: right;
  }
}
