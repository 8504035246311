ul li {
  color: var(--warna-putih);
  font-size: 1rem;
  font-weight: 400;
}

li a.active {
  color: var(--warna-kuning);
  background: none;
}

ul li:hover {
  color: var(--warna-kuning);
  cursor: pointer;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 1rem;  
  border: none;
  outline: none;
  color: white;
  padding: .7rem 1rem;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.navbar span:hover, .dropdown:hover .dropbtn {
  color: var(--warna-kuning);
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #004B1C;
  min-width: 8rem;
  min-height: 1.5rem;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  color: #fff;
  padding: 1rem;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content span:hover {
  color: var(--warna-kuning);
}

.dropdown:hover .dropdown-content {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  border-radius: .5rem;
}

@media only screen and (max-width: 768px) {
  ul li{
    text-align: center;
    margin-bottom: 1rem;
  }
  ul li .dropdown {
    width: 100vw;
    display: flex;
    justify-content: center;
  }
}
