.slideshow-container {
  width: 50%;
  height: 100%;
  /* position: absolute; */
  margin: auto;
}

.mySlides {
  display: none;
}

.dot {
  cursor: pointer;
  height: 1rem;
  width: 1rem;
  /* height: 0.789rem;
  width: 0.789rem; */
  margin: 0 0.3rem;
  background-color: rgb(235, 235, 235);
  border-radius: 50%;
  display: inline-block;
  transition: all 0.6s ease;
}

.active,
.dot:hover {
  background-color: var(--warna-kuning);
  width: 2rem;
  border-radius: 0.5rem;
}

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

.mySlides img {
  width: 10rem;
  height: 20rem;
  object-fit: cover;
}

.dots {
  /* text-align: center; */
  position: absolute;
  bottom: 0;
  left: 39%;
}

@keyframes fade {
  from {
    opacity: 0.1;
    transform: translate(10rem);
  }
  to {
    opacity: 1;
    /* transform: translate(10rem); */
  }
}

/* SETUP MOBILE */
@media only screen and (max-width: 768px) {
  .mySlides img {
    width: 10rem;
    height: 20rem;
    object-fit: cover;
  }
}
