.kontak-footer {
  height: 140vh;
  width: 100vw;
  margin-top: -120vh;
  position: relative;
}

.kontak-footer img {
  position: absolute;
  height: 100%;
  width: 100%;
}

.kontak-footer-container {
  width: 80vw;
  margin: 0 auto;
  margin-top: 28rem;
  position: relative;
  padding-top: 60vh;
  padding-bottom: 5rem;
}
.kontak-footer-contents {
  position: relative;
  z-index: 2;
}

.kontak-footer-contents form {
  height: 22.575rem;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.form-kontents {
  display: flex;
}

.group-control {
  flex: 1;
  margin-right: 1rem;
}

.group-control label {
  display: block;
  font-size: 1.053rem;
  color: var(--warna-kuning);
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.group-control input,
select {
  width: 100%;
  background: none;
  border: none;
  outline: none;
  border-bottom: solid 0.1rem rgb(255, 255, 255);
  padding: 0.5rem 0;
  margin-bottom: 2rem;
  font-size: 1.368rem;
  color: rgb(255, 255, 255);
}

.group-control input::placeholder {
  color: rgb(253, 253, 253);
  font-size: 1.368rem;
}

.button-kirim {
  margin: 0 auto;
}

.button-kirim button {
  width: 10.789rem;
  height: 2.895rem;
  background-color: var(--warna-kuning);
  color: var(--warna-hijau);
  border: none;
  font-size: 1.579rem;
  font-weight: 600;
  border-radius: 0.789rem;
  transition: all 0.5s ease;
}

.button-kirim button:hover {
  background-color: var(--warna-hijau);
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.berhasil-wrapper {
  margin: 0 auto;
  width: 27%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--warna-hijau);
  padding: 0.3rem;
  background-color: var(--warna-kuning);
  border-radius: 0.6rem;
  transition: all 0.5s ease;
}

.berhasil-wrapper p {
  font-size: 1.579rem;
  text-transform: capitalize;
}

/* Mobile */

@media only screen and (max-width: 768px) {
  .kontak-footer-container {
    width: 90vw;
    padding-top: 40vh;
  }

  .form-kontents {
    display: block;
  }

  .group-control label {
    font-size: 0.857rem;
    margin-bottom: 0.3rem;
  }

  .group-control input,
  select {
    padding: 0.4rem 0;
    margin-bottom: 1.5rem;
    font-size: 1.071rem;
  }

  .group-control input::placeholder {
    font-size: 1.071rem;
  }

  .button-kirim button {
    width: 5.786rem;
    height: 1.456rem;
    font-size: 0.857rem;
    border-radius: 0.214rem;
  }
}
