.layout {
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .layout {
    width: 90vw;
  }
}
