/* Footer */
.footer-container {
  width: 80vw;
  position: absolute;
  bottom: 0.5rem;
  left: 10vw;
  z-index: 5;
}

.footer-contents {
  position: relative;
  z-index: 2;
  margin-bottom: 1.2rem;
}

.footer-contents .sosmed-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sosmed-container .sosmed {
  width: 3.421rem;
  height: 3.421rem;
  position: relative;
}

.sosmed img {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all 0.5s ease;
}

.sosmed img:hover {
  cursor: pointer;
  transform: scale(0.9);
}

.navigasi-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigasi-container .logo-footer {
  width: 7.017rem;
  height: 5.263rem;
  position: relative;
}

.logo-footer img {
  width: 100%;
  height: 100%;
  position: absolute;
}

.nav-footer {
  color: rgb(255, 255, 255);
}

.nav-footer ul {
  padding: 0;
  display: flex;
}

.nav-footer ul li {
  margin-left: 2rem;
  font-size: 1rem;
  font-weight: 500;
}

.nav-footer ul li:hover {
  cursor: pointer;
  color: var(--warna-kuning);
}

.garis-panjang {
  border-top: 0.1rem solid var(--warna-kuning);
}

.copy-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgb(255, 255, 255);
  font-size: 0.842rem;
}

/* Mobile */

@media only screen and (max-width: 768px) {
  .footer-container {
    width: 90vw;
    left: 5vw;
  }
  .navigasi-container {
    display: block;
  }

  .nav-footer ul {
    display: block;
  }

  .nav-footer ul li {
    margin-left: 0;
  }

  .navigasi-container .logo-footer {
    margin: 0 auto;
  }

  .copy-right {
    display: block;
    text-align: center;
  }

  .copy-right-adem-sari {
    margin-bottom: 0.7rem;
  }

  .sosmed-container .sosmed {
    width: 1.9rem;
    height: 1.9rem;
  }
}
