.not-found {
  background-image: url("../../images/background-other-page.webp");
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  width: 100vw;
  height: 100vh;
  padding: 1rem;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.not-found p {
  margin-bottom: 1rem;
  font-size: 2rem;
}
