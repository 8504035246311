.produk {
  background-image: url("../../images/background-other-page.webp");
  background-size: cover;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  /* background-position: center; */
  position: relative;
  width: 100%;
  /* height: 160vh; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding-bottom: 5rem;
}

.produk-container {
  margin-top: 6rem;
  margin-bottom: 20rem;
  width: 80vw;
  position: relative;
  z-index: 1;
}

.produk-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6rem;
  margin-bottom: 2rem;
}

.produk-title {
  flex: 1.8;
  padding: 0 0.5rem;
}

.produk-title h3 {
  font-family: "Gold Girls";
  font-size: 3.947rem;
  font-weight: 400;
  letter-spacing: 0.01rem;
  color: var(--warna-kuning);
  padding: 0 0.5rem;
  text-shadow: -0.211rem -0.211rem 0 var(--warna-hijau),
    0.211rem -0.211rem 0 var(--warna-hijau),
    -0.211rem 0.211rem 0 var(--warna-hijau),
    0.211rem 0.211rem 0 var(--warna-hijau);
}

.produk-cari {
  flex: 1;
  height: 2.368rem;
  /* width: 26.842rem; */
  border: 0.1rem solid var(--warna-kuning);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.6rem;
}

.produk-cari input {
  border: none;
  outline: none;
  background: none;
  padding: 0.3rem 0;
  color: white;
  font-size: 0.842rem;
}

.produk-cari img {
  width: 1.5rem;
  height: 1.5rem;
}

.produk-cari input::placeholder {
  font-size: 0.842rem;
  color: rgb(255, 255, 255);
}

.produk-kontents {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.produk-kontents-wrapper {
  width: 22.632rem;
  height: 19.474rem;
  border-radius: 0.526rem;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.5s ease;
  margin-bottom: 3rem;
}
.produk-kontents-wrapper:hover {
  box-shadow: 0px 0.5rem 0.05rem rgb(251, 255, 0),
    0 10px 20px rgb(250, 250, 250);
  /* transform: scale(1.02); */
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.7);
}
.produk-kontents-wrapper:hover .button-produk {
  visibility: visible;
  opacity: 1;
}

.produk-kontents-wrapper:hover .nama-produk {
  color: rgba(255, 255, 255, 0.3);
}

.foto-produk {
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0.5rem;
  position: relative;
}

.foto-produk img {
  width: 35%;
  height: 100%;
  object-fit: cover;
}

.tidak-ditemukan-wrapper {
  width: 100%;
  height: 44vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--warna-kuning);
  font-size: 1.6rem;
  font-style: italic;
  font-weight: bolder;
}

/* .foto-produk img {
  position: absolute;
  width: 100%;
  height: 100%;
} */

/* .foto-produk .sachet {
  width: 6.368rem;
  height: 7.105rem;
} */

/* .foto-produk .chingku {
  width: 5.105rem;
  height: 7.105rem;
} */

/* .foto-produk .pet {
  width: 4.368rem;
  height: 7.105rem;
} */

/* .foto-produk .sejuk {
  width: 4.629rem;
  height: 7.105rem;
} */

.nama-produk {
  color: rgb(255, 255, 255);
  height: 20%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nama-produk h5 {
  font-size: 1.053rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}

.button-produk {
  position: absolute;
  width: 100%;
  height: 20%;
  bottom: 2rem;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.75s linear;
}

.image-wrapper {
  width: 6rem;
  display: flex;
  justify-content: space-between;
}

.image-wrapper img {
  width: 2.368rem;
  height: 2.368rem;
}

/* SETUP MOBILE */
@media only screen and (max-width: 768px) {
  .produk {
    background-image: url("../../images/background-other-page-mobile.png");
  }
  .produk-container {
    width: 90vw;
  }

  .produk-header {
    display: block;
  }

  .produk-title h3 {
    font-size: 2.143rem;
    margin-bottom: 0.7rem;
    text-shadow: -0.111rem -0.111rem 0 var(--warna-hijau),
      0.111rem -0.111rem 0 var(--warna-hijau),
      -0.111rem 0.111rem 0 var(--warna-hijau),
      0.111rem 0.111rem 0 var(--warna-hijau);
  }

  .produk-kontents-wrapper {
    width: 43.5vw;
    height: 10.141rem;
    margin-bottom: 1rem;
  }

  .produk-kontents-wrapper:hover {
    box-shadow: 0px 0.2rem 0.02rem rgb(251, 255, 0),
      0 5px 10px rgb(250, 250, 250);
  }

  .nama-produk h5 {
    font-size: 0.7rem;
  }

  .button-produk {
    bottom: 1.3rem;
  }

  .button-produk .image-wrapper {
    width: 5rem;
  }

  .button-produk .image-wrapper img {
    width: 1.7rem;
    height: 1.7rem;
  }
}
