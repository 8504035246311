.beranda {
  width: 100%;
  background-color: rgb(0, 0, 0);
  overflow-x: hidden;
}

/* .beranda > * {
  width: 100vw;
  height: 100vh;
} */
