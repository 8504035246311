.navbar {
  width: 100%;
  height: 6rem;
  background-color: rgba(0, 45, 15, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  transition: all 1s ease;
}

.solid {
  background-color: rgba(0, 45, 15, 0.9);
  height: 5rem;
}

.logo {
  flex: 2;
}

.logo img {
  width: 5rem;
  height: 3.75rem;
}

.navigasi {
  flex: 6;
}

.navigasi ul {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/* .navigasi ul li {
  color: var(--warna-putih);
  font-size: 1rem;
  font-weight: 400;
}

li a.active {
  color: var(--warna-kuning);
  background: none;
}

.navigasi ul li:hover {
  color: var(--warna-kuning);
  cursor: pointer;
} */

.button-belanja {
  flex: 2;
  display: flex;
  justify-content: flex-end;
}

.button-play {
  margin-left: 1rem;
  margin-right: 1rem;
}

.button-belanja button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7.9rem;
  height: 2.1rem;
  border-radius: 0.526rem;
  color: var(--warna-hijau);
  background-color: var(--warna-kuning);
  border: none;
}

.button-play button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.9rem;
  height: 1.9rem;
  border-radius: 0.526rem;
  color: var(--warna-hijau);
  background-color: var(--warna-kuning);
  border: none;
}

.button-menu {
  display: none;
}

.button-open button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.9rem;
  height: 1.9rem;
  /* border-radius: 0.526rem; */
  color: var(--warna-hijau);
  /* background-color: var(--warna-kuning); */
  border: none;
}

button img {
  width: 1.249rem;
  height: 1.054rem;
}

.button-belanja button:hover,
.button-play button:hover {
  background: rgb(255, 252, 95);
  cursor: pointer;
}

.btn-title {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.14rem;
  margin-left: 0.46rem;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .navbar {
    height: 5rem;
  }

  .navjo {
    background: var(--warna-hijau);
  }

  .navigasi {
    display: none;
  }

  .button-menu {
    display: block;
  }

  .button-menu button {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 1.249rem;
    height: 1.054rem; */
    background: none;
    border: none;
    cursor: pointer;
  }

  .button-menu button img {
    width: 1.642rem;
    height: 1.642rem;
  }

  .button-belanja button,
  .button-play button {
    width: 1.642rem;
    height: 1.642rem;
    border-radius: 50%;
    padding: 0.2rem;
  }

  .button-belanja span {
    display: none;
  }
}
