.kebijakan-kami {
  background-image: url("../../images/background-other-page.webp");
  background-size: cover;
  /* background-position: center; */
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.kebijakan-kami-container {
  margin-top: 8rem;
  margin-bottom: 25rem;
  width: 80vw;
  /* height: 60vh; */
  position: relative;
  z-index: 1;
}

.kebijakan-title h3 {
  font-family: "Gold Girls";
  font-size: 3.947rem;
  font-weight: 400;
  letter-spacing: 0.01rem;
  color: var(--warna-kuning);
  padding: 0 0.5rem;
  text-shadow: -0.211rem -0.211rem 0 var(--warna-hijau),
    0.211rem -0.211rem 0 var(--warna-hijau),
    -0.211rem 0.211rem 0 var(--warna-hijau),
    0.211rem 0.211rem 0 var(--warna-hijau);
  margin-bottom: 2rem;
}

.kebijakan-kami-wrapper {
  color: rgb(255, 255, 255);
}

.kebijakan-kami-wrapper p {
  font-size: 1.158rem;
  line-height: 1.6rem;
  letter-spacing: 0.01rem;
}

p.list {
  margin-top: 1rem;
}

p.detail__lists {
  padding-left: 1rem;
}

/* SETUP MOBILE */
@media only screen and (max-width: 768px) {
  .kebijakan-kami {
    background-image: url("../../images/background-other-page-mobile.png");
  }

  .kebijakan-kami-container {
    width: 90vw;
  }

  .kebijakan-title h3 {
    font-size: 2.143rem;
    margin-bottom: 0.7rem;
    text-shadow: -0.111rem -0.111rem 0 var(--warna-hijau),
      0.111rem -0.111rem 0 var(--warna-hijau),
      -0.111rem 0.111rem 0 var(--warna-hijau),
      0.111rem 0.111rem 0 var(--warna-hijau);
  }

  .kebijakan-kami-wrapper p {
    font-size: 0.857rem;
    line-height: 1.143rem;
  }
}
