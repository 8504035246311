.register {
  background-image: url("../../images/background-other-page.webp");
  background-size: cover;
  background-repeat: no-repeat;
  /* background-position: center; */
  position: relative;
  width: 100%;
  /* height: 100vh; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding-bottom: 5rem;
}

.register-container {
  margin-top: 10rem;
  margin-bottom: 20rem;
  width: 80vw;
  /* height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* z-index: 1; */
}

.register-kontents-wrapper {
  height: 32.895rem;
  width: 27.895rem;
  border-radius: 1rem;
  border: 0.1rem solid rgba(255, 242, 0, 1);
  padding: 1rem;
  background-color: rgba(0, 0, 0, .5);
  /* position: relative; */
}
.register-title {
  margin-bottom: 1rem;
}
.register-title h4 {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
}

.form-register {
  position: relative;
  /* width: 100%; */
}

.group-register label {
  font-size: 0.947rem;
  color: var(--warna-kuning);
  display: block;
  margin-bottom: 0.5rem;
}

.group-register input {
  width: 100%;
  height: 2.368rem;
  background: none;
  border-radius: 1rem;
  outline: none;
  border: solid 0.1rem var(--warna-kuning);
  padding: 0.5rem;
  margin-bottom: 2rem;
  font-size: 0.842rem;
  color: rgb(255, 255, 255);
}

.group-register input::placeholder {
  font-size: 0.842rem;
  color: rgb(255, 255, 255);
}

.button-wrapper-register button {
  width: 100%;
  height: 2.368rem;
  border: none;
  border-radius: 0.789rem;
  background-color: var(--warna-kuning);
  color: var(--warna-hijau);
  font-size: 0.947rem;
  font-weight: 700;
  transition: all 0.5s ease;
  text-transform: uppercase;
  position: absolute;
  bottom: -1rem;
}

.button-wrapper-register button:hover {
  background-color: var(--warna-hijau);
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.keterangan-tambahan {
  display: block;
  text-align: center;
  margin-top: 2rem;
  color: #fff;
}

.keterangan-tambahan p {
  font-size: 0.632rem;
  text-align: center;
}

.keterangan-tambahan span {
  font-weight: bolder;
}
.keterangan-tambahan span:hover {
  font-weight: bolder;
  color: var(--warna-kuning);
  cursor: pointer;
}

/* SETUP MOBILE */
@media only screen and (max-width: 768px) {
  .register {
    background-image: url("../../images/background-other-page-mobile.png");
  }

  .register-container {
    width: 90vw;
  }

  .register-kontents-wrapper {
    height: 32.876rem;
  }

  .register-title h4 {
    font-size: 1.5rem;
  }

  .keterangan-tambahan {
    position: absolute;
    bottom: 1rem;
    left: 9.5rem;
  }
}
