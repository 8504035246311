.tentang-kami {
  background-image: url("../../images/background-other-page.webp");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  /* height: 100vh; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding-bottom: 5rem;
}

.tentang-kami-container {
  margin-top: 6rem;
  margin-bottom: 18rem;
  width: 62vw;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.tentang-kami-title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(255, 255, 255);
}

.tentang-kami-title span {
  font-size: 1.632rem;
  font-style: italic;
  line-height: 2.368rem;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.01rem;
}

/* SETUP MOBILE */
@media only screen and (max-width: 768px) {
  .tentang-kami {
    background-image: url("../../images/background-other-page-mobile.png");
  }

  .tentang-kami-container {
    width: 90vw;
  }

  .tentang-kami-title span {
    font-size: 1.071rem;
    line-height: 1.429rem;
  }
}
