.ads-kontents-wrapper {
  width: 22.632rem;
  height: 19.474rem;
  border-radius: 0.526rem;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.5s ease;
  /* margin-bottom: 3rem; */
}
.ads-kontents-wrapper:hover {
  /* transform: scale(1.02); */
  box-shadow: 0px 0.5rem 0.05rem rgb(251, 255, 0);
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.7);
}
.ads-kontents-wrapper:hover .button-ads {
  visibility: visible;
  opacity: 1;
}

.ads-kontents-wrapper:hover .nama-ads {
  color: rgba(255, 255, 255, 0.1);
}

.rekomendasi {
  width: 5.789rem;
  height: 1.579rem;
  position: absolute;
  top: 1rem;
  left: 0;
  background-color: var(--warna-kuning);
  color: var(--warna-hijau);
  border-radius: 0 0.789rem 0.789rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rekomendasi h5 {
  font-size: 0.684rem;
}

.foto-ads {
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0.5rem;
}

.foto-ads .sachet {
  width: 45%;
  height: 100%;
  object-fit: cover;
}

.foto-ads .chingku {
  width: 5.105rem;
  /* height: 7.105rem; */
}

.foto-ads .pet {
  width: 4.368rem;
  /* height: 7.105rem; */
}

.foto-ads .sejuk {
  width: 4.629rem;
  /* height: 7.105rem; */
}

.nama-ads {
  color: rgb(255, 255, 255);
  height: 20%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nama-ads h5 {
  font-size: 1.053rem;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
}

.button-ads {
  position: absolute;
  width: 100%;
  height: 20%;
  bottom: 2rem;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.75s linear;
}

.image-wrapper {
  width: 6rem;
  display: flex;
  justify-content: space-between;
}

.image-wrapper img {
  width: 2.368rem;
  height: 2.368rem;
}
