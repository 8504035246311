.info-kamu {
  width: 100vw;
  height: 100vh;
  margin-top: -36vh;
  position: relative;
  z-index: 2;
  padding-top: 0.5vh;
}

.info-kamu img {
  position: absolute;
  width: 100%;
  height: 60%;
}

.info-kamu-container {
  width: 80vw;
  margin: 0 auto;
  margin-top: 12rem;
}
.info-kamu-contents {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--warna-kuning);
  margin-bottom: 2rem;
}

.info-kamu-contents h3 {
  font-family: "Gold Girls";
  font-size: 3.947rem;
  font-weight: 400;
  line-height: 5.105rem;
  letter-spacing: 0.01rem;
  padding: 0 0.5rem;
  text-shadow: -0.211rem -0.211rem 0 var(--warna-hijau),
    0.211rem -0.211rem 0 var(--warna-hijau),
    -0.211rem 0.211rem 0 var(--warna-hijau),
    0.211rem 0.211rem 0 var(--warna-hijau);
  flex: 1.8;
  margin-top: 2rem;
}

.info-kamu-contents h4 {
  font-size: 1.316rem;
  font-weight: 400;
}

.info-kamu-contents h4:hover {
  cursor: pointer;
  color: rgb(218, 196, 0);
}

.info-kamu-contents h4 img {
  position: relative;
  width: 1.066rem;
  height: 0.711rem;
}

/* Search */
.produk-cari {
  flex: 1;
  height: 2.368rem;
  /* width: 26.842rem; */
  border: 0.1rem solid var(--warna-kuning);
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.6rem;
}

.produk-cari input {
  border: none;
  outline: none;
  background: none;
  padding: 0.3rem 0;
  color: white;
  font-size: 0.842rem;
}

.produk-cari input::placeholder {
  font-size: 0.842rem;
}

/*  */
.info-kamu-container-produk {
  width: 80vw;
  margin: 0 auto;
  /* margin-top: 1rem; */
}
.info-kamu-contents-produk {
  position: relative;
  width: 100%;
  /* height: 100%; */
  z-index: 10;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.info-kamu-produk-group {
  /* width: 26.526rem; */
  width: 28rem;
  height: 18rem;
  position: relative;
  display: flex;
  /* flex: 1; */
  margin-bottom: 3rem;
  /* margin-right: 2rem; */
}

.info-kamu-produk-group .image-info {
  flex: 1;
  position: relative;
  width: 13.158rem;
  height: 100%;
  border-radius: 0.526rem;
  overflow: hidden;
}

.image-info img {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: all 0.5s ease-out;
  object-fit: cover;
  object-position: 28% 50%;
}

.image-info img:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.info-kamu-produk-group .konten-info {
  flex: 1;
  padding: 0 1rem;
  color: white;
  position: relative;
}

.konten-info .span {
  font-size: 0.789rem;
  margin-bottom: 0.8rem;
}

.konten-info .judul {
  font-size: 1.158rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
  text-transform: capitalize;
}

.konten-info .summary {
  font-size: 0.895rem;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.25rem;
  margin-bottom: 1.8rem;
}

.konten-info .continue {
  width: 7rem;
  height: 1.1rem;
  border-bottom: 0.1rem solid white;
  color: white;
  font-size: 0.789rem;
}

.konten-info .continue:hover {
  cursor: pointer;
  color: var(--warna-kuning);
  border-bottom: 0.1rem solid var(--warna-kuning);
}

/* Mobile */

@media only screen and (max-width: 768px) {
  .info-kamu-container-produk {
    width: 90vw;
    overflow: hidden;
    height: 18rem;
  }

  .heightAuto {
    height: auto;
  }

  .info-kamu-container {
    width: 90vw;
  }

  .info-kamu-contents.titleBlock {
    display: block;
  }

  .info-kamu-contents h3 {
    font-size: 2.143rem;
    margin-top: 0;
  }
  .info-kamu-produk-group {
    width: 100%;
    height: 15.643rem;
    /* margin-right: 20rem; */
  }

  .info-kamu-produk-group .image-info {
    /* flex: 1.4; */
    height: 15.643rem;
  }

  .info-kamu-produk-group .konten-info {
    padding: 0 0 0 0.5rem;
  }

  .konten-info .judul {
    font-size: 0.929rem;
    margin-bottom: 0.4rem;
  }

  .konten-info .summary {
    font-size: 0.786rem;
    line-height: 1rem;
    margin-bottom: 1.3rem;
  }

  .info-kamu-contents h4 {
    font-size: 0.857rem;
  }
}
